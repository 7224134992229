<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<el-input v-model="word" placeholder="请输入角色名称" clearable></el-input>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
			</div>
			<div class="m_tools_content">
				<el-button @click="showInsert" class="m_add m_btn_common">添加</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<el-table :data="tableData" stripe>
				<el-table-column label="序号" type="index" width="60" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="roleName" label="角色名称" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="description" label="角色描述" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="操作" width="100" align="center">
					<template slot-scope="scope">
						<i class="el-icon-edit-outline m_edit" @click="showUpdate(scope.row)" title="修改"></i>
						<i class="el-icon-setting m_edit" @click="showMenuConfig(scope.row.roleId)" title="配置菜单"></i>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
		</div>
		<el-dialog :close-on-click-modal="false" :title="dialogTitle" :visible.sync="dialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<el-form ref="form" :model="form" label-width="80px" :rules="rules" style="padding-right: 10px;">
					<el-form-item label="角色名称" prop="name">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
					<el-form-item label="角色说明" prop="description">
						<el-input v-model="form.description"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="m_dialog_bottom">
				<el-button type="primary" @click="save" size="mini" class="m_btn_save">保存</el-button>
				<el-button @click="dialogVisible = false" size="mini" class="m_btn_cancel">取消</el-button>
			</div>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" title="配置权限" :visible.sync="menuDialogVisible" :modal="false" v-el-drag-dialog width="400px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<el-tree :data="treeData" :props="treeProps" ref="tree" node-key="privilegeId" highlight-current show-checkbox :expand-on-click-node="false">
				</el-tree>
			</div>
			<div class="m_dialog_bottom">
				<el-button type="primary" @click="saveMenuConfig" size="mini" class="m_btn_save">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE, APP_TYPE_CODE } from "@/utils/config";
	import {
		rolesSelect,
		rolesInsert,
		rolesUpdate,
		rolesDelete,
		roleprivilegesSelect,
		roleprivilegesInsert,
	} from "@/api/roles";
	import { privilegesSelect } from "@/api/authority";
	export default {
		directives: {
			elDragDialog,
		},
		data() {
			return {
				total: 0,
				currentPage: 1,
				selectedId: null,
				word: null,
				tableData: [],
				treeData: [],
				treeProps: {
					children: "children",
					label: "privilegeName",
				},
				dialogTitle: "添加",
				dialogVisible: false,
				menuDialogVisible: false,
				rules: {
					name: [{
							required: true,
							message: "角色名称不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 64,
							message: "角色名称过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 64,
							message: "角色名称过长",
							trigger: "blur"
						},
					],
					description: [{
							required: true,
							message: "角色说明不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 255,
							message: "角色说明过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 255,
							message: "角色说明过长",
							trigger: "blur"
						},
					],
				},
				form: {
					roleId: 0,
					name: "",
					description: "",
				},
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			dialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
					height: '200px'
				};
			},
		},
		components: {
			mPagination,
		},
		created() {
			this.select();
			this.privilegesSelect();
		},
		mounted() {},
		methods: {
			pageChange(page) {
				this.currentPage = page;
				this.select();
			},
			search() {
				this.select();
			},
			showInsert() {
				this.initForm();
				this.dialogTitle = "添加";
				this.dialogVisible = true;
			},
			showUpdate(data) {
				console.log(data);
				this.initForm();
				this.dialogTitle = "修改";
				this.form = {
					roleId: data.roleId,
					name: data.roleName,
					description: data.description,
				};
				this.dialogVisible = true;
			},
			showMenuConfig(id) {
				if(this.$refs.tree) {
					this.$refs.tree.setCheckedKeys([]);
				}
				this.menuDialogVisible = true;
				this.selectedId = id;
				this.roleprivilegesSelect(id);
			},
			initForm() {
				if(this.$refs.form) {
					this.$refs.form.resetFields();
				}
				this.form = {
					roleId: 0,
					name: "",
					description: "",
				};
			},
			save() {
				this.$refs.form.validate((valid) => {
					if(valid) {
						switch(this.dialogTitle) {
							case "添加":
								this.insert();
								break;
							case "修改":
								this.update();
								break;
						}
					} else {
						return false;
					}
				});
			},
			saveMenuConfig() {
				const result = this.$refs.tree.getCheckedNodes();
				let list = [];
				result.forEach((item) => {
					list.push({
						privilegeId: item.privilegeId,
						enabled: true,
					});
				});
				this.roleprivilegesInsert({
					roleId: this.selectedId,
					rolePrivileges: list,
				});
			},
			async select() {
				const res = await rolesSelect({
					name: this.word,
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				});
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async insert() {
				const res = await rolesInsert(this.form);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "添加成功",
					type: "success",
				});
				this.select();
				this.dialogVisible = false;
			},
			async update() {
				const res = await rolesUpdate(this.form);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "修改成功",
					type: "success",
				});
				this.select();
				this.dialogVisible = false;
			},
			async delete() {
				const res = await rolesDelete();
				const {
					pageCount,
					results
				} = res;
			},
			async privilegesSelect() {
				const res = await privilegesSelect({
					typeId: APP_TYPE_CODE,
				});
				const {
					pageCount,
					results
				} = res;
				this.treeData = results;
			},
			async roleprivilegesSelect(data) {
				const res = await roleprivilegesSelect({
					roleId: data,
				});
				const {
					pageCount,
					results
				} = res;
				let list = [];
				results.forEach((item) => {
					list.push(item.privilege.privilegeId);
				});
				this.$refs.tree.setCheckedKeys(list);
			},
			async roleprivilegesInsert(data) {
				const res = await roleprivilegesInsert(data);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "添加成功",
					type: "success",
				});
				this.menuDialogVisible = false;
			},
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>