import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 查询权限
export const privilegesSelect = data => request(`${XHR_URL}/api/v1/privileges`, data, "GET");
// 新增权限
export const privilegesInsert = data => request(`${XHR_URL}/api/v1/privileges`, data, "POST");
// 修改权限
export const privilegesUpdate = data => request(`${XHR_URL}/api/v1/privileges`, data, "PUT");
// 删除权限
export const privilegesDelete = data => request(`${XHR_URL}/api/v1/privileges`, data, "DELETE");
// 查询权限菜单
export const privilegemenusSelect = data => request(`${XHR_URL}/api/v1/privilegemenus`, data, "GET");
// 添加权限菜单
export const privilegemenusInsert = data => request(`${XHR_URL}/api/v1/privilegemenus`, data, "POST");